@import "../../theme.scss";

#wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;

    #chat-box {
        width: 492px;
        height: 629px;
        padding: 21px 21px 1px 21px;
        border-radius: 12px;
        border: solid 1px #ececec;
        padding: 21px;
        display: flex;
        flex-direction: column;

        #header {
            height: 95px;
            border-radius: 12px;
            margin-bottom: 19.5px;
            display: flex;
            align-items: center;

            .back {
                color: $primary-color;
                font-weight: 600;
                display: flex;
                align-items: center;
                cursor: pointer;
            }
            .image {
                height: 60px;
                width: 60px;
                border: 1px solid #ddd;
                border-radius: 100%;
                box-sizing: border-box;
                margin-left: 30px;
            }

            #profile {
                margin-left: 15px;

                .name {
                    color: $text-color;
                    font-weight: bold;
                    font-size: 18px;
                }

                .role {
                    color: $text-color;
                    opacity: .7;
                    font-size: 14px;
                    margin-bottom: 3px;
                }

                .stars {
                    svg {
                        fill: #1479b8;
                    }
                }
            }
        }

        #messages-wrapper {
            flex: 1;
        }

        #action-wrapper {
            margin-top: 20px;
        }
    }
}

@media screen and (max-width: 1300px) {
    #wrapper {
        #chat-box {
            width: calc(375px - 42px);
            flex: 1;
            border: none;
            border-radius: 0px;
            padding-top: 0px;

            #header {
                .back {
                    color: $text-color;
                    
                    span {
                        display: none;
                    }
                }
            }
        }
    }
}