/* You can add global styles to this file, and also import other style files */
@import './theme.scss';

html, body {
    margin: 0px;
    height: 100%;
    width: 100%;
    font-family: Helvetica;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
}

a,h1,h2,h3,h4,h5,h6,p,span,div {
    font-family: Helvetica;
    margin: 0px;
}

#root {
    min-height: 100%;
}

.regular-font {
    font-size: 20px;
}
.primary-color {
    color: $primary-color;
}
