#container {
  width: 100%;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

#router-outlet {
  display: flex;
  flex-direction: column;
  flex: 1;
}

@media screen and (max-width: 796px) {
  #container {
    min-width: 375px;
  }
}