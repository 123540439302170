@import "../../../../theme.scss";

#select {
    display: grid;
    grid-template-columns: calc(50% - 5px) calc(50% - 5px);
    grid-column-gap: 10px;
    
    .option {
        margin-bottom: 10px;
        color: $primary-color;
        border: 1px solid $primary-color;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 7px;
        font-weight: bold;
        font-size: 15px;
        letter-spacing: .3px;
        cursor: pointer;

        &:hover {
            background-color: $primary-color;
            color: white;
        }
        &.selected {
            background-color: $primary-color;
            color: white;
        }
    }
}