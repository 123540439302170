#footer {
    background-color: #4a4a4a;
    height: 219px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    .links {
        margin-top: 20px;
        width: 663px;
        display: flex;
        justify-content: space-between;
    
        .link {
            font-size: 16px;
            color: white;
            letter-spacing: .4px;
            position: relative;
            cursor: pointer;
            text-decoration: none;
    
            &::after {
                content: ' ';
                width: 5px;
                height: 5px;
                background-color: white;
                position: absolute;
                border-radius: 50px;
                top: 7px;
                left: calc(100% + 10px);
            }
            &:last-child::after {
                content: none;
                display: none;
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    #footer {
        padding-top: 30px;
        padding-bottom: 20px;
        height: unset;
    
        .links {
            margin-top: 30px;
            width: unset;
            display: flex;
            flex-direction: column;
    
            .link {
                text-align: center;
                margin-bottom: 10px;

                &::after {
                    display: none;
                }
            }
        }
    }
}