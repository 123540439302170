@import '../../theme.scss';

.bold {
    color: #5ec275;
    font-weight: bold;
}

.card {
    height: 569px;
    width: $desktop-max-width;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    max-width: $desktop-max-width;
    margin: 0 auto;
    box-sizing: border-box;

    &.padding {
        padding: 0px $desktop-inner-padding;
    }
    
    .text {
        width: 445px;
        color: $text-color;

        .title {
            font-size: 36px;
            font-weight: bold;
        }

        .description {
            margin-top: 24px;
        }
    }

    .img img {
        max-height: 346px;
    }

    &#are-you-ready {
        background: #f5f7f8;
        max-width: 100%;
        

        .text {
            padding-left: 260px;
            
            .question {
                font-size: 26.7px;
                margin-top: 30px;
            }
        }

        .img {
            position: absolute;
            right: calc(50% - 720px + 230px);
            bottom: 0px;
            display: flex;

            img {
                max-height: 100%;
            }
        }
    }
}
.card:first-child {
    height: 696px;

    .text {
        .title {
            font-size: 50px;
            line-height: 1.32;
            
            p {
                font-weight: 700;
            }
        }

        .description {
            line-height: 1.4;
            letter-spacing: 0.4px;

            &.mobile {
                display: none;
            }
        }
    }

    #illustration {
        position: absolute;
        right: 0px;
        bottom: 0px;
    }
}
.sign-up-button {
    background-color: $primary-color;
    text-transform: uppercase;
    width: 257px;
    height: 61px;
    border: none;
    border-radius: 5px;
    box-shadow: 10px 19px 20px 0 rgba(234, 51, 130, 0.06);
    margin-top: 50px;
    cursor: pointer;

    a {
        text-decoration: none;
        color: white;
        font-weight: bold;
        font-size: 18px;
        letter-spacing: 0.27px;
    }
}
.how-it-works {
    height: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .header {
        margin-top: 130px;

        .title {
            font-size: 36px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 16px;
        }

        .description {
            width: 728px;
            font-size: 20px;
            line-height: 1.65;
            text-align: center;
        }
        
    }
    .grid {
        margin-top: 137px;
        display: grid;
        grid-template-columns: repeat(3, 255px);
        column-gap: 172px;
        row-gap: 132px;

        .item {
            width: 255px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .description {
                text-align: center;
                margin-top: 30px;
            }
        }
    }
}


@media screen and (max-width: 1300px) {
    .card {
        flex-direction: column-reverse;
        justify-content: flex-end;
        height: fit-content;
        min-width: $mobile-max-width;
        width: $mobile-max-width;
        margin-top: 140px;

        &.padding {
            padding: 0px $mobile-inner-padding;
        }
        &:nth-child(3) {
            flex-direction: column;
        }
        &:nth-child(4) {
            .sign-up-button {
                margin-top: 40px;
            }
        }

        .text {
            text-align: center;
            width: 100%;
            max-width: $mobile-max-width;
            margin-bottom: 30px;

            .title {
                font-size: 27px;
                font-weight: bold;

                p {
                    font-size: 27px;
                    font-weight: bold;
                }
            }

            .description {
                font-size: 18px;
                letter-spacing: .4px;
                line-height: 1.56;
            }
        }

        img {
            max-width: 250px;   
        }

        &#are-you-ready {
            flex-direction: column;
            background: #f5f7f8;
            width: 100%;
            height: 770px;
            max-width: 100%;
            padding: 0px 20px;
            padding-top: 30px;
            justify-content: flex-start;

            .text {
                padding-left: 0px;
                
                .question {
                    font-size: 23px;
                    margin-top: 30px;

                    &.question1 {
                        text-align: left;
                    }
                    &.question2 {
                        text-align: right;
                    }
                }
            }
    
            .img {
                right: unset;
                bottom: unset;
                top: 200px;
                max-width: 500px;

                img {
                    max-width: 100%;
                }
            }

            .sign-up-button {
                position: absolute;
                bottom: 40px;
            }
        }
    }

    .card:first-child {
        height: 558px;
        margin-top: 0px;

        .text {
            margin-top: 10px;

            .title, .title > p {
                font-size: 27px;
                font-weight: bold;
                line-height: 1.48;
                letter-spacing: .68px;
            }
    
            .description {
                line-height: 1.4;
                letter-spacing: 0.4px;

                &.mobile {
                    display: block;
                }

                &.desktop {
                    display: none;
                }
            }
        }
    
        #illustration {
            position: relative;
            width: 375px;
            
            img {
                max-width: 100%;
            }
        }
    }

    .sign-up-button {
        margin-top: 20px;
    }

    .how-it-works {
        height: unset;
        padding: $mobile-inner-padding;
        padding-bottom: 120px;
    
        .header {
            margin-top: 120px;
    
            .title {
                font-size: 27px;
            }
    
            .description {
                width: 100%;
                font-size: 18px;
            }
            
        }
        .grid {
            display: flex;
            flex-direction: column;

            .item > .description {
                font-size: 22px;
                margin-top: 80px;
            }
        }
    }
}