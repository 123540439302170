@import '../../../../theme.scss';

#currency {
    border: none;
    font-size: 31px;
    font-weight: bold;
    line-height: 0.52;
    letter-spacing: 0.62px;
    text-align: center;
    color: $text-color;
    outline: none;
    width: 100%;

    :focus {
        outline: none;
    }
    ::placeholder {
        margin-right: 15px;
        opacity: .3;
    }
}

#next-button {
    height: 50px;
    width: 100%;
    margin: 0 auto;
    margin-top: 100px;
    background-color: $primary-color;
    border: none;
    border-radius: 6px;
    color: white;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;

    &:disabled {
        background-color: $disabled-color;
    }
}