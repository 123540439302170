@import '../../../../theme.scss';

$option-color: #1479b8;

#multi-select {
    .option {
        color: $option-color;
        background-color: #f4f7f8;
        padding: 10px 7px;
        width: fit-content;
        border-radius: 5px;
        display: inline-block;
        margin-bottom: 10px;
        margin-right: 10px;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &.selected {
            background-color: $option-color;
            color: white;
        }

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 5px;
            height: 100%;
        }
    }

    #search {
        height: 36px;

        input {
            height: 100%;
            padding: 0px 7px;
            width: calc(100% - 71px);
            border: 1px solid #dddddd;
            outline: none;
            border-radius: 5px;
            border-right: none;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            color: $text-color;

            &::placeholder {
                opacity: .3;
            }
        }
        button {
            background-color: $primary-color;
            width: 35px;
            color: white;
            height: 100%;
            border: none;
            box-sizing: content-box;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            padding: 1px 10px;
            cursor: pointer;

            &:disabled {
                background-color: $disabled-color;
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    #multi-select {
        .option {
            font-size: 14px;
        }

        #search {
            input, button {
                font-size: 14px;
            }
        }
    }   
}