@import '../../theme.scss';
$light-grey: #737c87;

#signup {
    max-width: $desktop-max-width;
    width: $desktop-max-width;
    padding: 0px $desktop-inner-padding;
    padding-top: 122px;
    margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex: 1;
    margin-bottom: 125px;

    .left {
        p {
            color: $text-color;
            font-size: 50px;
            font-weight: bold;
        }

        img {
            max-width: 440px;
            margin-top: 47px;
        }
    }

    .right {
        width: 539px;
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            font-size: 20px;
            text-align: center;
            letter-spacing: 0.4px;
            color: $light-grey;
        }

        #linkedin-button {
            width: 450px;
            height: 52px;
            margin-top: 24px;
            background-color: #2867b2;
            border-radius: 6px;
            display: flex;
            align-items: center;
            position: relative;
            cursor: pointer;

            #linkedin-logo {
                max-height: 25px;
                margin-left: 12px;
            }

            p {
                color: white;
                font-size: 16px;
                font-weight: bold;
                width: 388px;
                position: absolute;
                right: 10px;
            }

            .vertical-border {
                width: 0.3px;
                height: 32px;
                background-color: #fff;
                opacity: .5;
                margin-left: 12px;
            }
        }

        #or {
            margin-top: 40px;
            font-size: 27px;
            font-weight: bold;
            letter-spacing: .9px;
            color: $text-color;
            width: 100%;
            position: relative;

            &::after, &::before {
                content: ' ';
                display: block;
                height: 1px;
                width: 239px;
                background-color: #d8d8d8;
                position: absolute;
                top: 16px;
            }
            &::after {
                right: 0px;
            }
            &::before {
                left: 0px;
            }
        }

        #label {
            margin-top: 40px;
        }

        input {
            width: 450px;
            height: 50px;
            border-radius: 3px;
            color: #1479b8;
            border-color: #1479b8;
            border: 1px solid;
            margin-top: 25px;
            padding-left: 14px;
            font-size: 18px;;
            outline: none;
            box-sizing: border-box;

            &::placeholder {
                color: #1479b8;
                opacity: .5;
            }
        }

        #need-help {
            margin-top: 25px;
            font-size: 16px;
            color: $primary-color;
        }

        #next-button {
            height: 50px;
            width: 450px;
            margin-top: 25px;
            background-color: $primary-color;
            border: none;
            border-radius: 6px;
            color: white;
            font-size: 14px;
            font-weight: bold;
            cursor: pointer;

            &:disabled {
                background-color: $disabled-color;
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    #signup {
        max-width: $mobile-max-width;
        width: $mobile-max-width;
        padding: 0px $mobile-inner-padding;
        padding-top: 27px;
        flex-direction: column;
        align-items: center;
        margin-bottom: 50px;
    
        .left {
            p {
                font-size: 27px;
            }
    
            img {
                max-width: 250px;
                margin-top: 25px;
            }
        }
    
        .right {
            width: 331px;
    
            p {
                font-size: 15px;
                letter-spacing: 0.32px;
                line-height: 1.44;
            }
    
            #linkedin-button {
                width: 100%;
                margin-top: 25px;
                margin-bottom: 25px;

                p {
                    width: 269px;
                }
            }
    
            #or {
                margin-top: 25px;

                &::after, &::before {
                    width: 135px;
                    top: 19px;
                }
            }
    
            #label {
                margin-top: 25px;
            }
    
            input {
                width: 331px;
            }
    
            #need-help {
                font-size: 13px;
            }
    
            #next-button {
                width: 331px;
            }
        }
    }   
}