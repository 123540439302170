@import '../../../../theme.scss';

.message {
    width: calc(100% - 40px);
    background-color: #f8f8f8;
    margin-bottom: 10px;
    padding: 20px;
    font-size: 18px;
    letter-spacing: 0.27px;
    text-align: left;
    color: $text-color;
    border-radius: 17px;
    border-bottom-left-radius: 5px;

    &:last-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 17px;
    }
}

@media screen and (max-width: 1300px) {
    .message {
        font-size: 16px;
        letter-spacing: 0.24px;
    }
}