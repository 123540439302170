@import '../../../../theme.scss';

#ticontainer {
    background-color: #f8f8f8;
    margin-bottom: 10px;
    padding: 20px;
    color: $text-color;
    border-radius: 17px;
    width: fit-content;

    .tiblock {
        align-items: center;
        display: flex;
        height: 17px;
        width: fit-content;
    }

    .tidot {
        animation: mercuryTypingAnimation 1.5s infinite ease-in-out;
        border-radius: 10px;
        display: inline-block;
        height: 10px;
        margin-right: 2px;
        width: 10px;
        background-color: #90949c;
    }

    @keyframes mercuryTypingAnimation {
        0% {
            transform:translateY(0px);
        }
        28% {
            transform:translateY(-5px);
        }
        44% {
            transform:translateY(0px);
        }
    }

    .tidot:nth-child(1){
        animation-delay:200ms;
    }
    .tidot:nth-child(2){
        animation-delay:300ms;
    }
    .tidot:nth-child(3){
        animation-delay:400ms;
    }
}