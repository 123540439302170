@import '../../theme.scss';

#done-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 160px;
    padding-bottom: 130px;

    #left {
        width: 740px;

        p {
            margin-top: 113px;
            font-size: 50px;
            font-weight: bold;
            line-height: 1.32;
            color: $text-color;
            margin-bottom: 42px;
        }

        img {
            max-width: 540px;
        }
    }
    #right {
        display: flex;
        flex-direction: column;
        align-items: center;

        #title {
            margin-top: 245px;
            color: #1479b8;
            font-size: 36px;
            font-weight: bold;
            line-height: 1.11;
            letter-spacing: 0.9px;
            text-align: center;
            margin-bottom: 24px;
        }
        #description {
            height: 52px;
            align-self: stretch;
            flex-grow: 0;
            font-size: 20px;
            line-height: 1.3;
            text-align: center;
            color: $text-color;
            margin-bottom: 24px;
        }
        input {
            width: 450px;
            height: 50px;
            border-radius: 3px;
            border: 1px solid $text-color;
            padding-left: 14px;
            font-size: 18px;
            outline: none;
            box-sizing: border-box;
            margin-bottom: 24px;

            &::placeholder {
                opacity: .5;
            }
        }

        button {
            height: 50px;
            width: 450px;
            margin-top: 25px;
            background-color: $primary-color;
            border: none;
            border-radius: 6px;
            color: white;
            font-size: 14px;
            font-weight: bold;
            cursor: pointer;

            &:disabled {
                background-color: $disabled-color;
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    #done-wrapper {
        flex-direction: column;
        padding: 0 20px;
    
        #left {
            width: 100%;
    
            p {
                margin-top: 50px;
                font-size: 27px;
                margin-bottom: 22px;
                text-align: center;
            }
    
            img {
                max-width: 100%;
            }
        }
        #right {
            margin-bottom: 30px;

            #title {
                margin-top: 50px;
                font-size: 27px;
            }
            #description {
                font-size: 17px;
            }
            input {
                width: 100%;
                margin-bottom: 0px;
            }
    
            button {
                width: 100%;
            }
        }
    }   
}