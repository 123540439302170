$hamburger-layer-width         : 20px !default;
$hamburger-layer-height        : 2px !default;
$hamburger-layer-spacing       : 5px !default;
@import '../../theme.scss';
@import '../../../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss';

#navbar {
    width: 100%;
    height: 80px;
    padding: 0px $desktop-inner-padding;
    max-width: $desktop-max-width;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    box-sizing: border-box;
    position: relative;

    &.interview {
        display: none;
    }

    #links {
        a {
            margin-right: 49px;
            cursor: pointer;
            font-size: 18px;
            text-decoration: none;
            color: $text-color;

            &:last-child {
                margin-right: 0px;
            }
        }
    }

    #hamburger {
        display: none;
    }
}

@media screen and (max-width: 1300px) {
    #navbar {
        height: 60px;
        padding: 0 20px;
        justify-content: center;
        align-items: center;

        #links {
            display: none;
        }
        #logo {
            justify-self: center;
            img {
                max-width: 125px;
            }
        }
        #hamburger {
            display: inline-block;
            position: absolute;
            left: 20px;
        }
    }
}